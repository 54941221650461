import { FixedSizeList as List } from 'react-window';
import { Tooltip } from 'react-tooltip';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Button from 'common/components/Button';
import {
  CUSTOM_FLAG_ALERT_TYPE_ID,
  ALERT_SEVERITY_COLOR_MAP,
  AI_ALERTS_LIST,
} from 'common/components/constant';
import { openModal } from 'common/slice/modalSlice';
import { setCurrentPlayingTimestamp } from 'common/slice/playbackVideoSlice';
import { formatDate } from 'helpers/date';
import { orderBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import AlertStatus from './AlertStatus';
import getGroupedAlerts, {
  SortedAlerts,
  getFilteredAlerts,
  markCriticalAlertsAsReadInFirebase,
} from 'helpers/alerts';
import { Alerts } from 'globals/interfaces';
import { slideAlertIntoView } from 'helpers/playback';
import { gutterSize } from 'common/components/constant';
import { useParams } from 'react-router-dom';

interface Props {
  viewBy: string;
  alerts: Alerts;
  searchTerm: string;
  attendeeId: number;
  sessionUuid: string;
  sessionId: string;
  filterBy: string[];
  clearSearch: () => void;
}

const AlertList = ({
  viewBy,
  alerts,
  filterBy,
  searchTerm,
  attendeeId,
  sessionUuid,
  sessionId,
  clearSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const [openAccordion, setOpenAccordion] = useState(null);
  const [currentPlayingAlert, setCurrentPlayingAlert] = useState('');
  const alertsEndRef = useRef<HTMLDivElement>(null);
  const conversationRef = useRef<HTMLDivElement>();

  const { currentPlayingTime, isEmbeddedView } = useAppSelector((state) => state.playbackVideo);
  const { session_uuid } = useParams();
  const isPlaybackView = Boolean(session_uuid);

  const alertList = alerts ? Object.values(alerts) : [];
  const filteredAlerts = getFilteredAlerts(alertList, filterBy);

  const filteredBySearchTerm = searchTerm
    ? filteredAlerts?.filter((alert) => {
      return (
        alert.severity?.includes(searchTerm) ||
        alert.description?.toLowerCase().includes(searchTerm) ||
        alert.name?.toLowerCase().includes(searchTerm)
      );
    })
    : filteredAlerts;

  const groupedAlertsList = getGroupedAlerts(filteredBySearchTerm, viewBy, searchTerm.trim());

  useEffect(() => {
    if (currentPlayingTime) {
      const activeElement = slideAlertIntoView(currentPlayingTime, filteredAlerts, conversationRef);
      setCurrentPlayingAlert(activeElement?.id);
    }
  }, [currentPlayingTime]);

  const alertsUpdate = () => {
    if (alerts && !isPlaybackView) {
      if (alertsEndRef.current) {
        alertsEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
      markCriticalAlertsAsReadInFirebase(sessionId, alerts);
    }
  };

  useEffect(alertsUpdate, [alertList?.length]);

  const handleDismissFlag = (alertId: string) => {
    dispatch(
      openModal({
        attendeeId,
        type: 'dismiss-flag',
        sessionUuid,
        sessionId,
        alertId,
      }),
    );
  };

  const handleSeekToAlert = (timestamp: string) => {
    clearSearch();
    dispatch(
      setCurrentPlayingTimestamp({
        requestedTimestamp: timestamp,
      }),
    );
  };

  const calculateAlertListHeight = () => {
    const innerHeight = window.innerHeight;
    const totalItemsHeight = filteredBySearchTerm.length * (60 + gutterSize) + 2 * gutterSize;
    return totalItemsHeight < innerHeight ? totalItemsHeight : innerHeight;
  };

  const renderVirtualAlertList = () => {
    return (
      <List
        itemData={orderBy(
          filteredBySearchTerm,
          [(alert) => alert.timestamp || alert.updated_at],
          ['asc'],
        )}
        height={calculateAlertListHeight()}
        itemCount={filteredBySearchTerm.length}
        itemSize={60 + gutterSize}
        width={'100%'}
      >
        {({ data, index, style }) => {
          const alert = data[index];
          const tooltip = `alert_tooltip_${index}`;
          return (
            <div
              key={alert.timestamp}
              style={{ ...style, marginTop: gutterSize, marginBottom: gutterSize }}
              className="h-full px-2"
            >
              <div className="flex flex-col overflow-auto h-full">
                <div
                  id={alert.id}
                  key={`${alert.id}_timeline`}
                  className={`cursor-pointer mb-2 flex rounded-md pl-1 pr-2 py-1 align-middle items-center  
              ${currentPlayingAlert === alert.id
                      ? 'shadow-xl border-[1.5px] border-blue-500'
                      : 'border-[1.5px] border-slate-300'
                    }
              ${ALERT_SEVERITY_COLOR_MAP[alert.severity]?.secondary}
              `}
                >
                  <div
                    className={`rounded h-full w-[4px] p-[2px] ${ALERT_SEVERITY_COLOR_MAP[
                      alert.severity
                    ]?.primary}`}
                  ></div>
                  <div className="flex flex-col gap-0.5 justify-start p-2 pl-3 flex-grow text-xs">
                    <span className="font-semibold">
                      {formatDate(alert.timestamp ? alert.timestamp : alert.updated_at)}
                    </span>
                    <span
                      data-tooltip-id={tooltip}
                      className="alert-log-tooltip whitespace-nowrap overflow-hidden text-ellipsis"
                    >
                      {alert.name}
                    </span>
                    {AI_ALERTS_LIST.includes(alert.alert_type_id) && <span>{alert.description}</span>}
                    <Tooltip className="alert-log-tooltip z-[1000]" id={tooltip} place="top">
                      {alert.description}
                    </Tooltip>
                  </div>
                  {!isEmbeddedView && (
                    <AlertStatus
                      alert={alert}
                      tooltipId={`alert_${index}`}
                      handleDismissFlag={handleDismissFlag}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </List>
    );
  };

  const renderTypeGroup = () => {
    return (
      <div className="flex flex-col mb-2 overflow-auto">
        {groupedAlertsList &&
          orderBy(groupedAlertsList, ['max_updated_time'], ['desc']).map((item: any) => (
            <div key={item.alert_type_id} className="px-3">
              <div
                onClick={() =>
                  setOpenAccordion(item.alert_type_id !== openAccordion ? item.alert_type_id : null)
                }
                className="flex items-center justify-between p-2 mb-2 text-sm cursor-pointer bg-slate-300"
              >
                <p>
                  {viewBy === 'type' ? (
                    <>
                      <span>{item.name}</span>
                      {AI_ALERTS_LIST.includes(item.alert_type_id) && (
                        <>
                          <br />
                          <span>{item.description}</span>
                        </>
                      )}
                    </>
                  ) : (
                    item.severity
                  )}
                </p>
                <Button
                  className="text-slate-500"
                  icon={
                    openAccordion === item.alert_type_id ? (
                      <MinusCircleIcon className="w-5 h-5" />
                    ) : (
                      <PlusCircleIcon className="w-5 h-5" />
                    )
                  }
                />
              </div>
              {openAccordion === item.alert_type_id && (
                <div>
                  {orderBy(item.alerts, ['timestamp'], ['desc']).map((alert, index) => (
                    <div
                      key={alert.updated_at}
                      className={`mb-2 flex rounded-md pl-1 pr-2 py-1 align-middle items-center border border-slate-300 ${ALERT_SEVERITY_COLOR_MAP[
                        alert.severity
                      ]?.secondary}`}
                    >
                      <div
                        className={`rounded w-[4px] p-[2px] h-6 ${ALERT_SEVERITY_COLOR_MAP[
                          alert.severity
                        ]?.primary}`}
                      ></div>
                      <div className="flex flex-col justify-start flex-grow p-1 pl-2 text-xs">
                        <span>
                          {formatDate(alert.timestamp ? alert.timestamp : alert.updated_at)}
                        </span>
                        {alert.alert_type_id.toString() === CUSTOM_FLAG_ALERT_TYPE_ID && (
                          <>
                            {AI_ALERTS_LIST.includes(alert.alert_type_id) && (
                              <span>{alert.name}</span>
                            )}
                            <span>{alert.description}</span>
                          </>
                        )}
                      </div>
                      {!isEmbeddedView && (
                        <AlertStatus
                          alert={alert}
                          tooltipId={`alert_${index}`}
                          handleDismissFlag={handleDismissFlag}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  const renderSeverityGroup = () => {
    return (
      <div className="flex flex-col mb-2 overflow-auto">
        {groupedAlertsList?.map((item: SortedAlerts) => (
          <div key={`${item.severity}_sev`} className="px-3">
            <div
              onClick={() =>
                setOpenAccordion(item.severity !== openAccordion ? item.severity : null)
              }
              className="flex items-center justify-between p-2 mb-2 text-sm cursor-pointer bg-slate-300"
            >
              <p className="capitalize">{item.severity}</p>
              <Button
                className="text-slate-500"
                icon={
                  openAccordion === item.severity ? (
                    <MinusCircleIcon className="w-5 h-5" />
                  ) : (
                    <PlusCircleIcon className="w-5 h-5" />
                  )
                }
              />
            </div>
            {openAccordion === item.severity && (
              <div>
                {orderBy(item.alerts, ['timestamp'], ['desc']).map((alert, index) => (
                  <div
                    key={alert.updated_at}
                    className={`mb-2 flex rounded-md pl-1 pr-2 py-1 align-middle items-center border border-slate-300 ${ALERT_SEVERITY_COLOR_MAP[
                      alert.severity
                    ]?.secondary}`}
                  >
                    <div
                      className={`rounded w-1 h-12 ${ALERT_SEVERITY_COLOR_MAP[alert.severity]
                        ?.primary}`}
                    ></div>
                    <div className="flex flex-col gap-0.5 justify-start p-2 pl-3 flex-grow text-xs">
                      <span className="font-semibold">
                        {formatDate(alert.timestamp ? alert.timestamp : alert.updated_at)}
                      </span>
                      <span>{alert.name}</span>
                      {AI_ALERTS_LIST.includes(alert.alert_type_id) && <span>{alert.description}</span>}
                    </div>
                    {!isEmbeddedView && (
                      <AlertStatus
                        alert={alert}
                        tooltipId={`alert_${index}`}
                        handleDismissFlag={handleDismissFlag}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderAlertList = () => {
    return (
      <div className="flex flex-col m-2 overflow-auto" ref={conversationRef}>
        {orderBy(filteredBySearchTerm, ['timestamp'], ['asc']).map((alert, index) => {
          return (
            <div
              onClick={() => handleSeekToAlert(alert?.timestamp || alert?.updated_at)}
              id={alert.id}
              key={`${alert.id}_timeline`}
              className={`cursor-pointer mb-2 flex rounded-md pl-1 pr-2 py-1 align-middle items-center  
              ${currentPlayingAlert === alert.id
                  ? 'shadow-xl border-[1.5px] border-blue-500'
                  : 'border-[1.5px] border-slate-300'
                }
              ${ALERT_SEVERITY_COLOR_MAP[alert.severity]?.secondary}
              `}
            >
              <div
                className={`rounded h-full w-[4px] p-[2px] ${ALERT_SEVERITY_COLOR_MAP[
                  alert.severity
                ]?.primary}`}
              ></div>
              <div className="flex flex-col gap-0.5 justify-start p-2 pl-3 flex-grow text-xs">
                <span className="font-semibold">
                  {formatDate(alert.timestamp ? alert.timestamp : alert.updated_at)}
                </span>
                {AI_ALERTS_LIST.includes(alert.alert_type_id) && <span>{alert.name}</span>}
                <span>{alert.name}</span>
              </div>
              {!isEmbeddedView && (
                <AlertStatus
                  alert={alert}
                  tooltipId={`alert_${index}`}
                  handleDismissFlag={handleDismissFlag}
                />
              )}
            </div>
          );
        })}
        <div data-testid="alerts-end-ref" ref={alertsEndRef}></div>
      </div>
    );
  };

  const handleAlertList = () => (session_uuid ? renderAlertList() : renderVirtualAlertList());

  return viewBy === 'timeline'
    ? handleAlertList()
    : viewBy === 'type'
      ? renderTypeGroup()
      : renderSeverityGroup();
};
export default AlertList;
